import React from "react";
import Logo from "../../components/logo";
import SocialIcon from "../../components/social-icon";
import Privacy_Policy_eSthenos from "../../assets/policy-pdf/Privacy_Policy_eSthenos.pdf";
import Term_of_Service from "../../assets/policy-pdf/Term_of_Service.pdf";
import Refund_Policy from "../../assets/policy-pdf/Refund_Policy.pdf";

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer-section">
            <div className="footer-top position-relative">
                <img
                    className="footer-shape"
                    src={process.env.PUBLIC_URL + "/images/footer/1.png"}
                    alt="shape"
                />
                <div className="container">
                    <div className="row mb-n7">
                        <div className="col-lg-3 col-sm-6 mb-7">
                            <div className="footer-widget">
                                <Logo
                                    classOption="footer-logo mb-5"
                                    image={`${process.env.PUBLIC_URL}/images/logo/esthenos/esthenos.png`}
                                />
                                <p>
                                    We are a Fintech with difference, committed
                                    to deliver impact-centric solutions and
                                    building the Neo distribution channel for
                                    those who needed most
                                </p>
                                <ul className="footer-social-links">
                                    <li>
                                        <SocialIcon
                                            classOption="footer-social-link"
                                            path="https://www.facebook.com/"
                                            icon="icofont-facebook"
                                        />
                                    </li>
                                    <li>
                                        <SocialIcon
                                            classOption="footer-social-link"
                                            path="https://www.instagram.com/"
                                            icon="icofont-instagram"
                                        />
                                    </li>
                                    <li>
                                        <SocialIcon
                                            classOption="footer-social-link"
                                            path="https://twitter.com/"
                                            icon="icofont-twitter"
                                        />
                                    </li>
                                    <li>
                                        <SocialIcon
                                            classOption="footer-social-link"
                                            path="https://www.whatsapp.com/"
                                            icon="icofont-whatsapp"
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 mb-7"></div>
                        <div className="col-lg-3 col-sm-6 mb-7">
                            <div className="footer-widget">
                                <h4 className="title">Contact info</h4>
                                <ul className="address">
                                    <li>
                                        <a
                                            className="address-link"
                                            href="https://goo.gl/maps/dQZiVX9YmGSzHZAY8"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            No. 2151, Ground Floor, HAL 2nd
                                            Stage, 17th Main, Opp Indiranagar
                                            BESCOM, Bengaluru- 560008 (India)
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="address-link"
                                            href="tel:+918025209690"
                                        >
                                            +91-80-2520 9690
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="address-link"
                                            href="mailto:enquiry@esthenos.com"
                                        >
                                            enquiry@esthenos.com
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copy-right-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright-info text-center">
                                <p>
                                    Copyright &copy; {currentYear} with eSthenos
                                    Technologies Pvt. Ltd., All Rights Reserved
                                </p>
                                <div className="col-sm-12 mt-15">
                                    <a
                                        href={Privacy_Policy_eSthenos}
                                        style={{ textDecoration: "underline" }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Privacy Policy
                                    </a>
                                    &nbsp;&nbsp;
                                    <a
                                        href={Term_of_Service}
                                        style={{ textDecoration: "underline" }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Term of Service
                                    </a>
                                    &nbsp;&nbsp;
                                    <a
                                        href={Refund_Policy}
                                        style={{ textDecoration: "underline" }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Refund Policy
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
