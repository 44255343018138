import PropTypes from "prop-types";

const RoadMapSectionTitle = ({ subTitle, classOption }) => {
    return (
        <div className={`title-section ${classOption}`}>
            <span className="sub-title">{subTitle}</span>
        </div>
    );
};

RoadMapSectionTitle.propTypes = {
    subTitle: PropTypes.string,
    classOption: PropTypes.string,
};
RoadMapSectionTitle.defaultProps = {
    classOption: "section-title",
};

export default RoadMapSectionTitle;
