import React from "react";
import PropTypes from "prop-types";
import SectionTitle from "../../../components/section-title";
import CkycKeyFeatures from "../../../containers/CkycKeyFeatures";
import KeyFeaturesOfEsthenos from "../../../data/KeyFeaturesOfEsthenos.json";

const IconBoxContainer = ({ classOption }) => {
    return (
        <div
            className={`feature-section section-pt position-relative ${classOption}`}
        >
            <img
                className="path-img"
                src={`${process.env.PUBLIC_URL}/images/feature/shape.png`}
                alt="images_not_found"
            />
            <div className="container custom-container">
                <div className="col-xl-9 col-lg-9 mx-auto mb-3 text-center">
                    <SectionTitle
                        classOption="title-section"
                        subTitle="FEATURES"
                        title="Key Features of eSthenos CKYC Solution"
                        excerptClassOption="mb-10"
                        // excerpt="At VIAN, we research, identify and make available products and categories that suit the everyday financial needs of the Indian family. Our mission is to provide the best value possible for our customers, so that every rupee they allocate to us gives them more value for money than they would get anywhere else"
                    />
                </div>
                <CkycKeyFeatures data={KeyFeaturesOfEsthenos} />
            </div>
        </div>
    );
};

IconBoxContainer.propTypes = {
    classOption: PropTypes.string,
};

export default IconBoxContainer;
