import { useEffect, useRef } from "react";
import SectionTitle from "../../../components/section-title";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/modules/effect-fade/effect-fade";
import "swiper/modules/navigation/navigation";
import "swiper/modules/pagination/pagination";
import SwiperCore, { Navigation } from "swiper";
import Parallax from "parallax-js";

SwiperCore.use([Navigation]);
const EkycSolutions = () => {
    const sceneEl = useRef(null);

    const dataSet1 = [
        "Banks",
        "Fintech companies",
        "Non-Banking Finance Companies (NBFCs) / Housing Finance Companies (HFCs) / Non-Bank Prepaid Payment Instrument Issuers (PPI) / Payment System Operators",
        "Mutual Funds / Stockbrokers / Commodity Brokers / Investment Advisors",
        "Insurance Companies",
    ];
    const dataSet2 = [
        "Providing CKYC API for KYC data download, enabling efficient digital onboarding.",
        "Automating bulk upload submissions to CKYC with image compression, aadhaar masking, image quality  and data validation engines, ensuring zero rejections and 100% compliance.",
    ];

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        });

        parallaxInstance.enable();

        return () => parallaxInstance.disable();
    }, []);
    return (
        <div className="testimonial-section section-py position-relative">
            <img
                className="tetimonial-shape"
                src={`${process.env.PUBLIC_URL}/images/testimonial/shape.png`}
                alt=""
            />
            <div className="container">
                <div className="row mb-n7">
                    <div className="col-xl-7 col-lg-8 mb-8">
                        <div className="testimonial-wrap">
                            <SectionTitle
                                classOption="title-section"
                                subTitle="solution"
                                title="CKYC <span class='text-primary'>solution</span>"
                            />
                            <h5 className="happy-customer">
                                eSthenos CKYC solution is a software
                                specifically designed for the following entities
                            </h5>
                        </div>
                        <div className="testimonial-carousel position-relative">
                            {dataSet1.map((data, key) => {
                                return (
                                    <p key={key}>
                                        <i className="fa fa-angle-double-right" />{" "}
                                        <b className="high-light">{data}</b>
                                    </p>
                                );
                            })}
                        </div>
                    </div>

                    <div className="col-xl-5 col-lg-4 mb-4">
                        <div
                            className="testimonial-photo scene mt-10 mt-lg-0"
                            id="scene"
                            ref={sceneEl}
                        >
                            <div data-depth="0.2">
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/testimonial/1.png`}
                                    alt="testimonial-img"
                                />
                            </div>
                        </div>
                    </div>
                    <h5 className="happy-customer">
                        The solution facilitates seamless interaction with the
                        Central KYC Registry (CKYCR) managed by CERSAI under the
                        PMLA, achieving the following objectives
                    </h5>
                    {dataSet2.map((data, key) => {
                        return (
                            <p key={key}>
                                <i className="fa fa-angle-double-right" />{" "}
                                <b className="high-light">{data}</b>
                            </p>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default EkycSolutions;
