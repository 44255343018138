import React from "react";
import { Link } from "react-scroll";
import menuItems from "../menuItems.json"; // Adjust the path according to your project structure
// import { NavLink } from "react-router-dom";
const MainMenu = () => {
    //origenal menu routes using router
    // const menuData = [
    //     {
    //         title: "About",
    //         link: "/about",
    //         subMenu: [
    //             { title: "About Us", link: "/about" },
    //             { title: "Team", link: "/team" },
    //             { title: "Policy Guidelines", link: "/policy" },
    //         ],
    //     },
    // ];
    return (
        <nav className="main-menu d-none d-lg-block">
            <ul className="d-flex">
                {menuItems.map((item, index) => (
                    <li key={index}>
                        <Link
                            className="sub-menu-link text-nowrap"
                            style={{ cursor: "pointer" }}
                            to={item.to}
                            smooth={true}
                            duration={item.duration}
                        >
                            {item.label}
                        </Link>
                    </li>
                ))}
                {/* {menuData.map((menuItem, index) => (
                    <li key={index}>
                        <NavLink to={process.env.PUBLIC_URL + menuItem.link}>
                            {menuItem.title}
                        </NavLink>
                        {menuItem.subMenu && menuItem.subMenu.length > 0 && (
                            <ul className="sub-menu">
                                {menuItem.subMenu.map(
                                    (subMenuItem, subIndex) => (
                                        <li
                                            className="sub-menu-item"
                                            key={subIndex}
                                        >
                                            <NavLink
                                                className="sub-menu-link"
                                                to={
                                                    process.env.PUBLIC_URL +
                                                    subMenuItem.link
                                                }
                                            >
                                                {subMenuItem.title}
                                            </NavLink>
                                        </li>
                                    )
                                )}
                            </ul>
                        )}
                    </li>
                ))} */}
                {/* <li>
                    <NavLink exact to="/">
                        Home
                    </NavLink>
                </li>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/about"}>
                        About
                    </NavLink>
                    <ul className="sub-menu">
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/about"}
                            >
                                About Us
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/team"}
                            >
                                team
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/captain"}
                            >
                                Be a Captain
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/policy"}
                            >
                                Policy Guidelines
                            </NavLink>
                        </li>
                    </ul>
                </li>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/products"}>
                        Products
                    </NavLink>

                    <ul className="sub-menu">
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/products"}
                            >
                                Products
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/products-details/1"
                                }
                            >
                                Get inside products
                            </NavLink>
                        </li>
                    </ul>
                </li> */}
                {/* <li>
                    <NavLink to={process.env.PUBLIC_URL + "/service"}>
                        Service
                    </NavLink>

                    <ul className="sub-menu">
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/service"}
                            >
                                Service
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/service-details/1"
                                }
                            >
                                single service
                            </NavLink>
                        </li>
                    </ul>
                </li> */}
                {/* <li>
                    <NavLink to={process.env.PUBLIC_URL + "/faq"}>FAQ</NavLink>
                </li>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/blog"}>
                        Blog
                    </NavLink> */}
                {/* <ul className="sub-menu">
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/blog"}
                            >
                                Blog
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/blog-left-sidebar"
                                }
                            >
                                blog grid left sidebar
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/blog-right-sidebar"
                                }
                            >
                                blog grid right sidebar
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/blog-details/1"}
                            >
                                blog details
                            </NavLink>
                        </li>
                    </ul> */}
                {/* </li>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/career"}>
                        Career
                    </NavLink>
                </li>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/contact"}>
                        Contact
                    </NavLink>
                </li> */}
            </ul>
        </nav>
    );
};

export default MainMenu;
