import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const CkycKeyFeatures = ({ data }) => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        // Set the initial state
        handleResize();

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Clean up event listener on unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className="service-wrap">
            {data.map((feature, index) => {
                const isEven = index % 2 === 0;

                if (isMobile) {
                    return (
                        <div key={feature.id} className="service-item row">
                            <div className="service-thumb bg-light text-center col-lg-6 d-md-block">
                                <img
                                    src={`${process.env.PUBLIC_URL}${feature?.contentImage}`}
                                    alt=""
                                />
                            </div>
                            <div className={`title-section col-lg-6`}>
                                <p className="high-light mb-1">
                                    {feature.Heading}
                                </p>
                                <p className="fw-semi-bold">
                                    <b className="high-light">Feature :</b>
                                    {feature.Feature}
                                </p>
                                <div className="single-service-list">
                                    <b>Functionality :</b>
                                    {feature.Functionality.map(
                                        (func, funcIndex) => (
                                            <p key={funcIndex}>
                                                <i className="fa fa-angle-double-right"></i>
                                                <b className="high-light">
                                                    {func.title}:
                                                </b>
                                                {func.description}
                                            </p>
                                        )
                                    )}
                                    <p className="text-primary fw-semi-bold mb-6">
                                        <b>Benefit:</b> {feature.Benefit}
                                    </p>
                                </div>
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div key={feature.id} className="service-item row">
                            {!isEven ? (
                                <>
                                    <div className="title-section col-lg-6">
                                        <p className="es-sty-cust animated delay1 custom-font">
                                            {feature.Heading}
                                        </p>
                                        <p className="fw-semi-bold">
                                            <b className="high-light">
                                                Feature :
                                            </b>
                                            {feature.Feature}
                                        </p>
                                        <div className="single-service-list">
                                            <b>Functionality :</b>
                                            {feature.Functionality.map(
                                                (func, funcIndex) => (
                                                    <p key={funcIndex}>
                                                        <i className="fa fa-angle-double-right"></i>
                                                        <b className="high-light">
                                                            {func.title}:
                                                        </b>
                                                        {func.description}
                                                    </p>
                                                )
                                            )}
                                            <p className="text-primary fw-semi-bold mb-6">
                                                <b>Benefit:</b>{" "}
                                                {feature.Benefit}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="service-thumb bg-light text-center col-lg-6">
                                        <img
                                            src={`${process.env.PUBLIC_URL}${feature?.contentImage}`}
                                            alt=""
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="service-thumb bg-light text-center col-lg-6 d-md-block">
                                        <img
                                            src={`${process.env.PUBLIC_URL}${feature?.contentImage}`}
                                            alt=""
                                        />
                                    </div>
                                    <div className="title-section col-lg-6">
                                        <p className="es-sty-cust animated delay1 custom-font">
                                            {feature.Heading}
                                        </p>
                                        <p className="fw-semi-bold">
                                            <b className="high-light">
                                                Feature :
                                            </b>
                                            {feature.Feature}
                                        </p>
                                        <div className="single-service-list">
                                            <b>Functionality :</b>
                                            {feature.Functionality.map(
                                                (func, funcIndex) => (
                                                    <p key={funcIndex}>
                                                        <i className="fa fa-angle-double-right"></i>
                                                        <b className="high-light">
                                                            {func.title}:
                                                        </b>
                                                        {func.description}
                                                    </p>
                                                )
                                            )}
                                            <p className="text-primary fw-semi-bold mb-6">
                                                <b>Benefit:</b>{" "}
                                                {feature.Benefit}
                                            </p>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    );
                }
            })}
        </div>
    );
};

CkycKeyFeatures.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            Heading: PropTypes.string.isRequired,
            Feature: PropTypes.string.isRequired,
            Benefit: PropTypes.string.isRequired,
            Functionality: PropTypes.arrayOf(
                PropTypes.shape({
                    title: PropTypes.string.isRequired,
                    description: PropTypes.string.isRequired,
                })
            ).isRequired,
        })
    ).isRequired,
};

export default CkycKeyFeatures;
