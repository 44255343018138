import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import IconBoxContainer from "../containers/global/icon-box";
import IntroContainer from "../containers/home/intro";
import Roadmap from "../containers/home/roadmap1";
import ServiceListContainer from "../containers/service/service-list";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import EkycSolutions from "../containers/global/EkycSolutions";

const HomePage = () => {
    return (
        <React.Fragment>
            <Layout>
                <SEO title="esthenos - ckyc" />
                <div className="wrapper">
                    <Header />
                    <div id="">
                        <IntroContainer />
                    </div>
                    <div id="features">
                        <IconBoxContainer />
                    </div>
                    <div id="ckyc-flow">
                        <Roadmap />
                    </div>
                    <div id="use-cases">
                        <ServiceListContainer />
                    </div>
                    <div id="solution">
                        <EkycSolutions />
                    </div>
                    <div id="contactUs">
                        <Footer />
                    </div>
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default HomePage;
