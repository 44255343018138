import RoadMapSectionTitle from "../../../components/RoadMapSectionTitle";
import SectionTitle from "../../../components/section-title";

const Roadmap = () => {
    return (
        <div className="container about-us section-pt position-relative">
            <div className="row">
                <div className="col-xl-6 col-lg-8 mx-auto">
                    <RoadMapSectionTitle
                        classOption="title-section  text-center"
                        subTitle="CKYC Flow"
                    />
                </div>
            </div>
            <div className="service-section position-relative mt-3 mb-sm-0">
                <div className="row mt-sm-4">
                    <div className="col-xl-6 col-md-6 col-sm-12 align-items-center d-flex">
                        <div className="about-content">
                            <SectionTitle
                                classOption="title-section"
                                title={
                                    "Customer <span class='text-primary'>Onboarding</span>"
                                }
                            />
                            <p>
                                <i className="fa fa-angle-double-right"></i>
                                The customer approaches a financial institution
                                (FI) for a financial product or service.
                            </p>
                            <p>
                                <i className="fa fa-angle-double-right"></i>
                                The FI collects the customer’s KYC documents and
                                information.
                            </p>
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-6 col-sm-12 my-e-5 d-flex justify-content-center del-img-767">
                        <img
                            src={`${process.env.PUBLIC_URL}/images/roadmap/1111.png`}
                            alt="img"
                        />
                    </div>
                    <div className="col-xl-6 col-md-6 col-sm-12 mar-top-es d-flex justify-content-center del-img-767">
                        <img
                            src={`${process.env.PUBLIC_URL}/images/roadmap/2222.png`}
                            alt="img"
                        />
                    </div>
                    <div className="col-xl-6 col-md-6 col-sm-12 align-items-center d-flex">
                        <div className="about-content">
                            <SectionTitle
                                classOption="title-section"
                                title={
                                    "KYC<span class='text-primary'> Verification</span>"
                                }
                            />
                            <p>
                                <i className="fa fa-angle-double-right"></i>
                                The FI verifies the authenticity of the
                                documents and the customer’s identity.
                            </p>
                        </div>
                    </div>
                    <div className="bckimg-1">
                        <img
                            src={`${process.env.PUBLIC_URL}/images/left-dot.png`}
                            alt="shape"
                        />
                    </div>
                    <div className="col-xl-6 col-md-6 col-sm-12 align-items-center d-flex">
                        <div className="about-content">
                            <SectionTitle
                                classOption="title-section"
                                title={
                                    "Data Submission to <span class='text-primary'>CKYC Registry</span>"
                                }
                            />
                            <p>
                                <i className="fa fa-angle-double-right"></i>
                                Once verified, the FI submits the customer`s KYC
                                details to the CKYC registry managed by CERSAI.
                            </p>
                            <p>
                                <i className="fa fa-angle-double-right"></i>
                                The submission includes personal details,
                                photograph, and proof of identity and address.
                            </p>
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-6 col-sm-12 mar-top-es d-flex justify-content-center del-img-767">
                        <img
                            src={`${process.env.PUBLIC_URL}/images/roadmap/3333.png`}
                            alt="img"
                        />
                    </div>
                    <div className="bckimg-2">
                        <img
                            src={`${process.env.PUBLIC_URL}/images/right-dot-2.png`}
                            alt="shape"
                        />
                    </div>
                    <div className="col-xl-6 col-md-6 col-sm-12 mar-top-es d-flex justify-content-center del-img-767">
                        <img
                            src={`${process.env.PUBLIC_URL}/images/roadmap/4444.png`}
                            alt="img"
                        />
                    </div>
                    <div className="col-xl-6 col-md-6 col-sm-12 align-items-center d-flex">
                        <div className="about-content">
                            <SectionTitle
                                classOption="title-section"
                                title={
                                    "CKYC <span class='text-primary'>Identifier Generation</span>"
                                }
                            />
                            <p>
                                <i className="fa fa-angle-double-right"></i>
                                Upon successful processing, CERSAI generates a
                                14-digit CKYC identifier (CKYC ID) unique to the
                                customer.
                            </p>
                            <p>
                                <i className="fa fa-angle-double-right"></i>
                                The CKYC ID is sent back to the FI, which then
                                communicates it to the customer.
                            </p>
                        </div>
                    </div>
                    <div className="bckimg-3">
                        <img
                            src={`${process.env.PUBLIC_URL}/images/left-dot-2.png`}
                            alt="shape"
                        />
                    </div>
                    <div className="col-xl-6 col-md-6 col-sm-12 align-items-center d-flex">
                        <div className="about-content">
                            <SectionTitle
                                classOption="title-section"
                                title={
                                    "Customer <span class='text-primary'>Database Update</span>"
                                }
                            />
                            <p>
                                <i className="fa fa-angle-double-right"></i>
                                The customer’s CKYC ID and details are stored in
                                the FI`s database for future reference.
                            </p>
                            <p>
                                <i className="fa fa-angle-double-right"></i>
                                The customer can use this CKYC ID for KYC
                                verification with other financial institutions
                                without resubmitting the documents.
                            </p>
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-6 col-sm-12 mar-top-es d-flex justify-content-center del-img-767">
                        <img
                            src={`${process.env.PUBLIC_URL}/images/roadmap/5555.png`}
                            alt="img"
                        />
                    </div>
                    <div className="bckimg-4">
                        <img
                            src={`${process.env.PUBLIC_URL}/images/right-dot-3.png`}
                            alt="shape"
                        />
                    </div>

                    <div className="col-xl-6 col-md-6 col-sm-12 mar-top-es d-flex justify-content-center del-img-767">
                        <img
                            src={`${process.env.PUBLIC_URL}/images/roadmap/222.png`}
                            alt="img"
                        />
                    </div>
                    <div className="col-xl-6 col-md-6 col-sm-12 align-items-center d-flex">
                        <div className="about-content">
                            <SectionTitle
                                classOption="title-section"
                                title={
                                    "KYC<span class='text-primary'> Retrieval and Sharing</span>"
                                }
                            />
                            <p>
                                <i className="fa fa-angle-double-right"></i>
                                Other FIs can retrieve the customer’s KYC
                                details using the CKYC ID, subject to the
                                customer`s consent.
                            </p>
                            <p>
                                <i className="fa fa-angle-double-right"></i>
                                This process reduces redundancy and accelerates
                                the KYC process for subsequent financial
                                transactions.
                            </p>
                        </div>
                    </div>
                    <div className="bckimg-5">
                        <img
                            src={`${process.env.PUBLIC_URL}/images/left-dot-2.png`}
                            alt="shape"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Roadmap;
